import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useQuerySEO } from "./SEO.query"

interface SEOProps {
  description: string
  lang?: string
  meta?: Array<{ name: string; content: string }>
  title: string
  forceNoIndex?: boolean
  isCookieYesLoaded: boolean
}

export function SEOView({
  description,
  lang = "fr",
  meta = [],
  title,
  forceNoIndex = false,
  isCookieYesLoaded = true,
}: SEOProps) {
  const { site }: any = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const defaultTitle: string = site.siteMetadata?.title
  const metaDescription: string = description || site.siteMetadata.description

  var dataSEO = useQuerySEO()
  var dataType = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:type"
  )
  const metaType: string = dataType[0].Property ? dataType[0].Property : ""

  var dataLocale = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:locale"
  )
  const metaLocale: string = dataLocale[0].Property
    ? dataLocale[0].Property
    : ""

  var dataSiteName = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:site_name"
  )
  const metaSiteName: string = dataSiteName[0].Property
    ? dataSiteName[0].Property
    : ""

  var dataUrl = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:url"
  )
  const metaUrl: string = dataUrl[0].Property ? dataUrl[0].Property : ""

  var dataImage = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:image"
  )
  const metaImage: string = dataImage[0].Property ? dataImage[0].Property : ""

  var dataImageAlt = dataSEO.strapiGmHeader.TagSEO.filter(
    item => item.Name == "og:image:alt"
  )
  const metaImageAlt: string = dataImageAlt[0].Property
    ? dataImageAlt[0].Property
    : ""

  var metaRobots: string
  var metaFacebook: string
  if (process.env.GATSBY_ENABLE_INDEX_GOOGLE == "true") {
    metaRobots = !window.location.search ? "index" : "noindex"
    metaFacebook = "oq6gltjn4reiz2dcu2s1etgsracr5d"
    //if (window.location.search)
  } else {
    metaRobots = "noindex"
    metaFacebook = "not_prod else"
  }

  if (forceNoIndex) {
    metaRobots = "noindex"
    metaFacebook = "not_prod forcenoindex"
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:locale`,
          content: metaLocale,
        },
        {
          property: `og:site_name`,
          content: metaSiteName,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:alt`,
          content: metaImageAlt,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `facebook-domain-verification`,
          content: metaFacebook,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: metaRobots,
        },
      ].concat(meta)}
    >
      {/* <script src="https://cdn-eu.pagesense.io/js/lingemat/a81e256c721648bdbf36743f1ad370d6.js"></script> */}
      {/* <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/7f00f39aa0ef924cf9692159/script.js"></script> */}
      {/* pvf */}
      {isCookieYesLoaded && (
        <script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/5fa5af6a98f19e7dbd9a280c/script.js"
        ></script>
      )}
    </Helmet>
  )
}
