import React, { useEffect, useRef } from "react"
import { Link } from "./../Link"
import { Image, ButtonStyled } from "./ImageWithLinkOrNot.style"
import noImage from "./../../../images/no-image.jpg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

interface Props {
  className?: string
  objectFit?: string
  data: Record<string, any>
  onClick?: Function | undefined
  link?: string
}

export function ImageWithLinkOrNotView({
  className,
  data,
  objectFit,
  onClick = undefined,
  link,
}: Props) {
  var item = data
  if (data?.hasOwnProperty("Image")) {
    item = data.Image
  }

  // Si proprieté Link : il s'agit d'une image clickable balise <a>
  if (data?.hasOwnProperty("Link") && data.Link != "" && data.Link != null) {
    return (
      <Link id={data.id} link={data.Link}>
        <ImageOrDefaut
          className={className}
          item={item}
          objectFit={objectFit}
        />
      </Link>
    )
  }
  // Si link est passé : on connait le path
  if (link) {
    return (
      <Link link={link}>
        <ImageOrDefaut
          className={className}
          item={item}
          objectFit={objectFit}
        />
      </Link>
    )
  }
  // Si proprieté onCLick : il s'agit d'une image clickable balise <button>
  if (onClick) {
    return (
      <ButtonStyled onClick={onClick}>
        <ImageOrDefaut
          className={className}
          item={item}
          objectFit={objectFit}
        />
      </ButtonStyled>
    )
  }
  return (
    <ImageOrDefaut className={className} item={item} objectFit={objectFit} />
  )
}

const ImageOrDefaut = ({ className, item, objectFit }) => {
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!(item?.url || item?.localFile)) {
    return <img className={className} src={noImage} alt="no image" />
  }

  const alt = item?.alternativeText || item?.alt || "grandes marques"
  if (item?.localFile) {
    const image = getImage(item.localFile)
    return (
      <GatsbyImage
        className={className}
        image={image}
        objectFit={objectFit || "contain"}
        alt={alt}
      />
    )
  }
  return <Image className={className} src={item?.url} alt={alt} />
}