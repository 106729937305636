import React, { ReactNode } from "react"
import { StyledLink, StyledGatsbyLink } from "./Link.style"

interface Props {
  className?: string
  link: string
  children: ReactNode
}

export const LinkView = ({ link, children, className = "" }: Props) => {
  if (link && link.match(/http(.*)/gim)) {
    return (
      <StyledLink href={link+"/"} className={className}>
        {children}
      </StyledLink>
    )
  }
  return (
    <StyledGatsbyLink
      to={(link+"/").replace("//", "/")}
      className={className}
    >
      {children}
    </StyledGatsbyLink>
  )
}
